<!-- <template>
  <div style="width: 100%; height: 100%" ref="mychart"></div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: 'ChartstopBar',
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    deep: true,
    data() {
      this.initMyChart() // 重置图表
    }
  },
  mounted() {
    this.initMyChart()
  },
  methods: {
    initMyChart() {
      // 基于准备好的dom，初始化echarts实例
      let mychart = echarts.getInstanceByDom(this.$refs.mychart)
      if (mychart == null) mychart = echarts.init(this.$refs.mychart)
      // const xAxisData = Object.keys(this.data)
      const datas = this.data
      var colorList = ['#f36c6c', '#e6cf4e', '#20d180', '#0093ff']
      let maxArr = []
      if (datas.length > 0) {
        maxArr = new Array(datas.length).fill(100)
      } else {
        maxArr = []
      }

      let option = {
        title: {
          text: this.title,
          left: 'center',
          top: 15,
          textStyle: {
            fontSize: 16,
            fontWeight: 'normal'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          show: false
        },
        grid: {
          left: '-30%',
          right: '2%',
          top: '10%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          show: false,
          type: 'value'
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            // axisPointer: {
            //   label: {
            //     show: true,
            //     margin: 30
            //   }
            // },
            data: datas.map((item) => item.name),
            axisLabel: {
              margin: 100,
              fontSize: 14,
              align: 'left',
              color: '#333',
              rich: {
                a1: {
                  color: '#000',
                  backgroundColor: colorList[0],
                  width: 30,
                  height: 30,
                  align: 'center',
                  borderRadius: 2
                },
                a2: {
                  color: '#000',
                  backgroundColor: colorList[1],
                  width: 30,
                  height: 30,
                  align: 'center',
                  borderRadius: 2
                },
                a3: {
                  color: '#000',
                  backgroundColor: colorList[2],
                  width: 30,
                  height: 30,
                  align: 'center',
                  borderRadius: 2
                },
                b: {
                  color: '#000',
                  backgroundColor: colorList[3],
                  width: 30,
                  height: 30,
                  align: 'center',
                  borderRadius: 2
                }
              },
              formatter: function (params) {
                var index = datas.map((item) => item.name).indexOf(params)
                index = index + 1
                if (index - 1 < 3) {
                  return [
                    '{a' + index + '|' + index + '}' + '  ' + params
                  ].join('\n')
                } else {
                  return ['{b|' + index + '}' + '  ' + params].join('\n')
                }
              }
            }
          },
          {
            type: 'category',
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            data: datas.map((item) => item.count),
            axisLabel: {
              show: true,
              fontSize: 14,
              color: '#333',
              formatter: '{value}'
            }
          }
        ],
        series: [
          {
            z: 2,
            name: '占比',
            type: 'bar',
            barWidth: 20,
            zlevel: 1,
            data: datas.map((item, i) => {
              let itemStyle = {
                color: i > 3 ? colorList[3] : colorList[i]
              }
              return {
                value: item.value,
                itemStyle: itemStyle
              }
            }),
            label: {
              show: false,
              position: 'right',
              color: '#333333',
              fontSize: 14,
              offset: [10, 0]
            }
          },
          {
            name: '总数',
            type: 'bar',
            barWidth: 20,
            barGap: '-100%',
            itemStyle: {
              color: 'rgb(235, 238, 245)'
            },
            data: maxArr
          }
        ]
      }

      mychart.setOption(option)
      // window.addEventListener('resize', this.resize)
    }
  }
}
</script> -->
<template>
  <div style="width: 100%; height: 100%" ref="mychart"></div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: 'ChartstopBar',
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    deep: true,
    data() {
      this.initMyChart() // 重置图表
    }
  },
  mounted() {
    // this.initMyChart()
    setTimeout(() => {
      this.initMyChart()
      const resizeOb = new ResizeObserver((entries) => {
        for (const entry of entries) {
          echarts.getInstanceByDom(entry.target).resize()
        }
      })
      resizeOb.observe(this.$refs.mychart)
    })
  },
  methods: {
    initMyChart() {
      let mychart = echarts.getInstanceByDom(this.$refs.mychart)
      if (mychart == null) mychart = echarts.init(this.$refs.mychart)
      var data = [
        { code: '600519', stock: 'aaa', fundPost: '21.987691' },
        { code: '000858', stock: 'bbb', fundPost: '20.377176' },
        { code: '002475', stock: 'ccc', fundPost: '19.127404' },
        { code: '600276', stock: 'ddd', fundPost: '18.40882' },
        { code: '601318', stock: 'eee', fundPost: '17.980597' },
        { code: '000661', stock: 'fff', fundPost: '16.957898' },
        { code: '000333', stock: 'ggg', fundPost: '15.099577' },
        { code: '300760', stock: 'eee', fundPost: '14.76103' },
        { code: '300750', stock: 'ttt', fundPost: '13.002175' },
        { code: '601888', stock: 'fff', fundPost: '12.133536' }
      ]
      function contains(arr, dst) {
        var i = arr.length
        while ((i -= 1)) {
          if (arr[i] == dst) {
            return i
          }
        }
        return false
      }

      var attackSourcesColor = [
        new echarts.graphic.LinearGradient(0, 1, 1, 1, [
          { offset: 0, color: '#EB3B5A' },
          { offset: 1, color: '#FE9C5A' }
        ]),
        new echarts.graphic.LinearGradient(0, 1, 1, 1, [
          { offset: 0, color: '#FA8231' },
          { offset: 1, color: '#FFD14C' }
        ]),
        new echarts.graphic.LinearGradient(0, 1, 1, 1, [
          { offset: 0, color: '#F7B731' },
          { offset: 1, color: '#FFEE96' }
        ]),
        new echarts.graphic.LinearGradient(0, 1, 1, 1, [
          { offset: 0, color: '#395CFE' },
          { offset: 1, color: '#2EC7CF' }
        ])
      ]
      var attackSourcesColor1 = [
        '#EB3B5A',
        '#FA8231',
        '#F7B731',
        '#3860FC',
        '#1089E7',
        '#F57474',
        '#56D0E3',
        '#1089E7',
        '#F57474',
        '#1089E7',
        '#F57474',
        '#F57474'
      ]
      var attaData = []
      var attaName = []
      var topName = []
      data.forEach((it, index) => {
        attaData[index] = parseFloat(it.fundPost).toFixed(2)
        attaName[index] = it.stock
        topName[index] = `${it.code} ${it.stock}`
      })
      var salvProMax = [] //背景按最大值
      for (let i = 0; i < attaData.length; i++) {
        salvProMax.push(attaData[0])
      }
      function attackSourcesDataFmt(sData) {
        var sss = []
        sData.forEach(function (item, i) {
          let itemStyle = {
            color: i > 3 ? attackSourcesColor[3] : attackSourcesColor[i]
          }
          sss.push({
            value: item,
            itemStyle: itemStyle
          })
        })
        return sss
      }

      var option = {
        backgroundColor: '#fff',
        tooltip: {
          show: false,
          // backgroundColor: 'rgba(3,169,244, 0.5)', //背景颜色（此时为默认色）
          textStyle: {
            fontSize: 16
          }
        },
        color: ['#F7B731'],
        // legend: {
        //   pageIconSize: [12, 12],
        //   itemWidth: 20,
        //   itemHeight: 10,
        //   textStyle: {
        //     //图例文字的样式
        //     fontSize: 14,
        //     color: '#000'
        //   },
        //   selectedMode: false,
        //   data: ['个人所得(亿元)']
        // },
        title: {
          text: '钢结构缺陷',
          left: 'center',
          top: 14,
          textStyle: {
            fontSize: 16,
            fontWeight: 'normal'
          }
        },
        grid: {
          left: '2%',
          right: '2%',
          width: '80%',
          bottom: '2%',
          top: '14%',
          containLabel: true
        },
        xAxis: {
          type: 'value',

          splitLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisPointer: {
              label: {
                show: true
                //margin: 30
              }
            },
            padding: [5, 0, 0, 0],
            postion: 'right',
            data: attaName,
            axisLabel: {
              margin: 30,
              fontSize: 14,
              align: 'left',
              padding: [2, 0, 0, 0],
              color: '#000',
              rich: {
                nt1: {
                  color: '#000',
                  backgroundColor: attackSourcesColor1[0],
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 0,
                  lineHeight: '5',
                  padding: [0, 1, 2, 1]
                  // padding:[0,0,2,0],
                },
                nt2: {
                  color: '#000',
                  backgroundColor: attackSourcesColor1[1],
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 0,
                  padding: [0, 1, 2, 1]
                },
                nt3: {
                  color: '#000',
                  backgroundColor: attackSourcesColor1[2],
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 0,
                  padding: [0, 1, 2, 1]
                },
                nt: {
                  color: '#000',
                  backgroundColor: attackSourcesColor1[3],
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  lineHeight: 5,
                  borderRadius: 0,
                  padding: [0, 1, 2, 1]
                  // lineHeight: 5
                }
              },
              formatter: function (value, index) {
                index = contains(attaName, value) + 1
                if (index - 1 < 3) {
                  return ['{nt' + index + '|' + index + '}'].join('\n')
                } else {
                  return ['{nt|' + index + '}'].join('\n')
                }
              }
            }
          },
          {
            type: 'category',
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              textStyle: {
                color: '#000',
                fontSize: '14'
              }
            },
            data: attackSourcesDataFmt(attaName)
          },
          {
            //名称
            type: 'category',
            offset: -12,
            position: 'left',
            // axisLabel: {
            //   color: `#000`,
            //   fontSize: 10
            // },
            axisLine: {
              show: false
            },
            inverse: false,
            axisTick: {
              show: false
            },
            axisLabel: {
              interval: 0,
              color: ['#000'],
              align: 'left',
              verticalAlign: 'bottom',
              lineHeight: 32,
              fontSize: 14
            },
            data: topName
          }
        ],
        series: [
          {
            zlevel: 1,
            name: '个人所得(亿元)',
            type: 'bar',
            barWidth: '15px',
            animationDuration: 1500,
            data: attackSourcesDataFmt(attaData),
            align: 'center',
            itemStyle: {
              normal: {
                barBorderRadius: 10
              }
            },
            label: {
              show: false,
              fontSize: 14,
              color: '#000',
              textBorderWidth: 2,
              padding: [2, 0, 0, 0]
            }
          },
          {
            name: '个人所得(亿元)',
            type: 'bar',
            barWidth: 15,
            barGap: '-100%',
            margin: '20',
            data: salvProMax,
            textStyle: {
              //图例文字的样式
              fontSize: 14,
              color: '#000'
            },
            itemStyle: {
              normal: {
                color: '#ebeef5',
                //width:"100%",
                fontSize: 14,
                barBorderRadius: 30
              }
            }
          }
        ]
      }

      mychart.setOption(option)
    }
  }
}
// window.onload = function(){
//   initEchartsFh();
// }
// window.onresize = function(){
//   initEchartsFh();
// }
</script>
