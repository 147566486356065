<template>
  <div class="create">
    <!-- card -->
    <el-card class="box-card">
      <!-- card header -->
      <div slot="header" class="clearfix">
        <span>用户创建</span>
        <el-button
          class="tz-r tz-create-btn"
          type="primary"
          round
          @click="jump('/users')"
          >返回</el-button
        >
      </div>
      <!-- card header -->

      <!-- card content -->
      <tz-form
        width="80px"
        :formConfig="formConfig"
        :formBtns="formBtns"
        @submit="submitFn"
      ></tz-form>
      <!-- card content -->
    </el-card>
    <!-- card -->
  </div>
</template>

<script>
import { brequest } from '@/utils/brequest'
import { SHA256 } from '@/utils/util'

export default {
  methods: {
    async submitFn(formData) {
      if (this.submited) return
      this.$loading({
        text: '用户信息添加中'
      })
      let user = JSON.parse(JSON.stringify(formData))
      user.password = SHA256(user.password)
      let ret = await brequest({
        url: '/user/addUser',
        data: user
      })
      this.$loading().close()
      if (ret.id) {
        this.submited = true
        this.$message({
          message: '用户信息添加成功',
          type: 'success'
        })
        this.$router.go(-1)
      }
    },
    async queryRoles() {
      let ret = await brequest({
        url: '/user/queryAllRoles'
      })
      this.formConfig.find((rec) => rec.field === 'roleId').payload = ret.map(
        (rec) => {
          return {
            value: rec.id,
            label: rec.roleName
          }
        }
      )
    },
    async checkLoginName(rule, value, callback) {
      let ret = await brequest({
        url: '/user/countUserByLoginName',
        data: { loginName: value }
      })
      if (ret > 0) {
        return callback('登录名有重复')
      }
      return callback()
    },
    async checkMobilePhone(rule, value, callback) {
      if (!/^1\d{10}$/.test(value)) return callback('手机号格式有误')
      let ret = await brequest({
        url: '/user/countUserByMobilePhone',
        data: { mobilePhone: value }
      })
      if (ret > 0) {
        return callback('手机号有重复')
      }
      return callback()
    }
  },
  mounted() {
    this.queryRoles()
  },
  data() {
    return {
      // 表单配置
      formConfig: [
        {
          label: '账号',
          field: 'loginName',
          type: 'text',
          rules: [
            { required: true, message: '账号必填', trigger: 'blur' },
            {
              validator: this.checkLoginName,
              trigger: 'blur'
            }
          ]
        },
        {
          label: '姓名',
          field: 'userName',
          type: 'text',
          rules: [{ required: true, message: '姓名必填', trigger: 'blur' }]
        },
        {
          label: '手机号',
          field: 'mobilePhone',
          type: 'text',
          rules: [
            { required: true, message: '手机号必填', trigger: 'blur' },
            {
              validator: this.checkMobilePhone,
              trigger: 'blur'
            }
          ]
        },
        {
          label: '角色',
          field: 'roleId',
          type: 'select',
          payload: [],
          rules: [{ required: true, message: '角色必填', trigger: 'blur' }]
        },
        {
          label: '密码',
          field: 'password',
          type: 'password',
          showPwd: true,
          rules: [{ required: true, message: '密码必填', trigger: 'blur' }]
        }
        // {
        //   label: "密保问题",
        //   field: "question",
        //   type: "select",
        //   payload: [
        //     { label: "母亲的名字", value: "母亲的名字" },
        //     { label: "爷爷的名字", value: "爷爷的名字" },
        //     { label: "您其中一位老师的名字", value: "您其中一位老师的名字" },
        //   ],
        //   rules: [{ required: true, message: "密保问题必填", trigger: "blur" }],
        // },
        // {
        //   label: "密保答案",
        //   field: "answer",
        //   type: "text",
        //   rules: [{ required: true, message: "密保问题必填", trigger: "blur" }],
        // },
      ],
      formBtns: [
        {
          content: '创建',
          width: '185px',
          type: 'primary'
        },
        {
          content: '重置',
          width: '185px',
          type: ''
        }
      ]
    }
  }
}
</script>

<style></style>
