<template>
  <div class="roles">
    <tz-card title="角色创建" url="/roles" btnTitle="返回">
      <!-- 内容 -->
      <tz-form
        width="80px"
        :formConfig="formConfig"
        :formBtns="formBtns"
        @submit="submitFn"
      ></tz-form>
      <!-- 内容 -->
    </tz-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 表单配置
      formConfig: [
        {
          label: '角色名',
          field: 'role_name',
          type: 'text',
          rules: [{ required: true, message: '角色名比填', trigger: 'blur' }]
        },
        {
          label: '角色描述',
          field: 'role_desc',
          type: 'text',
          rules: [{ required: true, message: '角色描述比填', trigger: 'blur' }]
        }
      ],

      formBtns: [
        {
          content: '创建',
          type: 'primary'
        },
        {
          content: '重置',
          type: ''
        }
      ]
    }
  },
  methods: {
    submitFn(formData) {
      console.log('更新数据处理', formData)
    }
  }
}
</script>

<style></style>
