<template>
  <div class="unitindex">
    <el-card class="box-card">
      <!-- card header -->
      <div slot="header" class="clearfix">
        <span>已确认工资列表</span>
      </div>
      <!-- card header -->

      <!-- card content -->
      <!-- 筛选 -->
      <el-row>
        <!-- 时间选择 -->
        <el-col :span="6">
          <el-date-picker
            value-format="yyyy-MM"
            format="yyyy-MM"
            v-model="pickerDate"
            type="month"
            :editable="false"
            placeholder="请选择月份(默认上月)"
            @change="handleDateChange"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="6">
          <el-input
            placeholder="请输入姓名"
            v-model="personName"
            clearable
            class="input-with-select"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="userSearch"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="10">&nbsp;</el-col>
        <el-col :span="2">
          <el-button
            class="tz-r tz-create-btn"
            type="primary"
            round
            @click="jump('/empsalarystats')"
            >返回</el-button
          >
        </el-col>
      </el-row>
      <!-- 筛选 -->

      <!-- 表格 -->
      <TzTable :tableData="statsList" :columns="columns" :summary="true" />

      <!-- card content -->
    </el-card>
    <!-- card -->
  </div>
</template>

<script>
import { queryBackups } from '@/api/salarystats'
import { welfareList } from '@/api/welfare'
import TzTable from '@/components/tz-table/Index.vue'
export default {
  components: {
    TzTable
  },
  data() {
    return {
      page: 1, //当前页
      size: '10',
      total: 100,
      downloadLoading: false, // 下载
      editUnit: {},
      statsList: [],
      formInline: {
        id: '',
        highTemperaturePay: '',
        festivitiesPay: ''
      },
      personName: '', // 员工姓名
      pickerDate: '', //日期 时间戳
      columns: [
        {
          field: 'personId',
          title: '工号',
          width: '100'
        },
        {
          field: 'personName',
          title: '姓名',
          width: '70'
        },
        {
          field: 'cardNo',
          title: '身份证号',
          width: '100'
        },
        {
          field: 'basePay',
          title: '基本工资'
        },
        // {
        //   field: 'lifePay',
        //   title: '年限工资'
        // },
        // {
        //   field: 'certPay',
        //   title: '持证工资'
        // },
        {
          field: 'titlePay',
          title: '职称工资'
        },
        {
          field: 'postPay',
          title: '职位工资'
        },
        // {
        //   field: 'performance',
        //   title: '绩效工资'
        // },
        // {
        //   field: 'leaderPay',
        //   title: '组长提成'
        // },
        {
          field: 'total',
          title: '合计1',
          width: '100'
        },
        {
          field: 'highTemperaturePay',
          title: '高温补贴',
          width: '70'
        },
        {
          field: 'festivitiesPay',
          title: '过节费',
          width: '60'
        },
        {
          title: '补发',
          field: 'complementPay'
        },
        {
          field: 'total2',
          title: '合计2'
        },
        {
          title: '社保',
          field: 'se'
        },
        {
          title: '公积金',
          field: 'pf'
        },
        {
          title: '个税',
          field: 'tax'
        },
        {
          title: '扣款',
          field: 'deductPay'
        },
        {
          field: 'payableSalary',
          title: '应发工资',
          width: '100'
        }
      ],
      unitname: ''
    }
  },
  created() {
    //默认显示上月日期
    let date = new Date(new Date() - 30 * 24 * 3600 * 1000)
    let dateString =
      date.getFullYear() +
      (date.getMonth() + 1 < 10 ? '0' : '') +
      (date.getMonth() + 1)
    let monthValue = dateString.substring(dateString.length - 2)
    let yearValue = dateString.slice(0, -2)
    dateString = yearValue + '-' + monthValue
    this.pickerDate = this.pickerDate ? this.pickerDate : dateString
    this.getListBydate()
  },
  methods: {
    //选择日期搜索
    handleDateChange(dialogPickerDate) {
      if (dialogPickerDate == null) {
        this.pickerDate = ''
      }
      this.page = 1
      this.getStatsList()
      this.getListBydate()
    },

    //根据时间获取补贴
    getListBydate() {
      let welparams = {
        date: this.pickerDate
      }
      welfareList(welparams).then((res) => {
        console.log(res.data, '根据时间获取补贴')
        if (res.code == 200) {
          let infos = res.data
          this.formInline.highTemperaturePay = infos.highTemperaturePay
          this.formInline.festivitiesPay = infos.festivitiesPay
          this.formInline.id = infos.id
        } else {
          this.formInline.highTemperaturePay = ''
          this.formInline.festivitiesPay = ''
          this.formInline.id = ''
        }
      })
    },
    // 列表
    getStatsList() {
      let unitparams = {
        date: this.pickerDate,
        personName: this.personName
        // pageSize: this.size,
        // pageNum: this.page
      }
      queryBackups(unitparams).then((res) => {
        console.log(res.data, '单位列表')
        if (res.code == 200) {
          if (res.data) {
            this.statsList = res.data
          }
          // let infoArr = res.data.records
          // this.total = res.data.total
          // this.statsList = infoArr
        }
      })
    },

    // 模糊查询
    userSearch() {
      this.page = 1
      this.getStatsList()
    }
  },
  mounted() {
    this.getStatsList()
  }
}
</script>

<style lang="scss" scoped>
.unitindex {
  .el-form-item {
    margin-bottom: 0;
  }
  .el-form-item_label {
    color: blue;
  }
}

::v-deep .el-table {
  overflow-x: auto;
}
::v-deep .el-table__header-wrapper {
  overflow: visible;
}
::v-deep .el-table__body-wrapper {
  overflow: visible;
}
::v-deep .el-table__footer-wrapper {
  overflow: visible;
}
::v-deep.el-table .el-table__footer-wrapper .cell {
  font-size: 16px;
  color: red;
}
::v-deep .el-table::after {
  position: relative;
}
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow: visible;
}
</style>
