<template>
  <el-dialog
    title="分配角色"
    :visible.sync="state"
    width="30%"
    :before-close="handleClose"
  >
    <!-- 内容 -->
    <tz-form
      width="80px"
      :formConfig="formConfig"
      :formBtns="formBtns"
      @submit="submitFn"
    ></tz-form>
    <!-- 内容 -->
  </el-dialog>
</template>

<script>
export default {
  props: {
    state: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      // 表单配置
      formConfig: [
        {
          label: '当前用户',
          field: 'uname',
          disabled: true,
          type: 'text',
          rules: [{ required: true, message: '用户名比填', trigger: 'blur' }]
        },
        {
          label: '当前角色',
          disabled: true,
          field: 'role_name',
          type: 'text',
          rules: [{ required: true, message: '角色比填', trigger: 'blur' }]
        },
        {
          label: '选择角色',
          field: 'role_id',
          type: 'select',
          payload: [
            { label: '超级管理员', value: 1 },
            { label: '财务', value: 2 },
            { label: '人事', value: 3 }
          ],
          rules: [{ required: true, message: '选择角色必填', trigger: 'blur' }]
        }
      ],

      formBtns: [
        {
          width: '185px',
          content: '更新',
          type: 'primary'
        }
        // {
        //   content: "重置",
        //   type: "",
        // },
      ]
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    submitFn(formData) {
      console.log('更新数据处理', formData)
    }
  }
}
</script>

<style></style>
