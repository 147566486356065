<template>
  <div style="width: 100%; height: 100%" ref="mychart"></div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: 'ChartsBar',
  props: {
    title: {
      type: String,
      required: true
    },
    topText: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  watch: {
    data() {
      this.initMyChart() // 重置图表
    }
  },
  mounted() {
    // this.initMyChart()
    setTimeout(() => {
      this.initMyChart()
      const resizeOb = new ResizeObserver((entries) => {
        for (const entry of entries) {
          echarts.getInstanceByDom(entry.target).resize()
        }
      })
      resizeOb.observe(this.$refs.mychart)
    })
  },
  // beforeDestroy() {
  //   window.removeEventListener('resize', this.resize)
  // },
  methods: {
    // resize() {
    //   this.mychart.resize()
    // },
    initMyChart() {
      // 基于准备好的dom，初始化echarts实例
      let mychart = echarts.getInstanceByDom(this.$refs.mychart)
      if (mychart == null) mychart = echarts.init(this.$refs.mychart)
      const xAxisData = this.data.map((item) => item.name)
      const seriesData = this.data.map((item) => item.score)
      // const xAxisData = Object.keys(this.data)
      // const seriesData = Object.values(this.data)
      let option = {
        // 标题
        title: {
          text: this.title,
          left: 'center',
          top: 15,
          // bottom: '20%',
          // padding: [10, 0],
          textStyle: {
            // lineHeight: 96,
            // height: 96,
            // color: '#000',
            fontSize: 16,
            fontWeight: 'normal'
          }
        },
        // 工具提示
        tooltip: {
          trigger: 'axis',
          // formatter: '{b} <br/>{a} : {c}',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xAxisData,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            // min: (value) => {
            //   return value.min
            // },
            // max: 100
            min: (value) => {
              // return value.min
              return Math.floor(value.min / 10) * 10
            },
            max: (value) => {
              return Math.ceil(value.max)
              // return Math.ceil(value.max / 100) * 100
            }
          }
        ],
        // 颜色配置
        color: ['#2d8cf0'],
        // 类型配置
        series: [
          {
            name: this.topText,
            // name: '得分',
            // 类型配置
            type: 'bar', //柱状
            barMaxWidth: '40%',
            // barMinHeight: '20%',
            // 数据配置
            data: seriesData,
            itemStyle: {
              // normal: {
              label: {
                show: true,
                fontSize: 14,
                position: 'top',
                color: '#000',
                formatter: '{c}'
              }
              // }
            }
          }
        ]
      }
      mychart.setOption(option)
      // window.addEventListener('resize', this.resize)
    }
  }
}
</script>
