<template>
  <div class="create">
    <!-- card -->
    <el-card class="box-card">
      <!-- card header -->
      <div slot="header" class="clearfix">
        <span>单位创建</span>
        <el-button
          class="tz-r tz-create-btn"
          type="primary"
          round
          @click="jump('/unitmanage')"
          >返回</el-button
        >
      </div>
      <!-- card header -->

      <!-- card content -->
      <tz-form
        width="110px"
        :formConfig="formConfig"
        :formBtns="formBtns"
        @submit="submitFn"
      ></tz-form>
      <!-- card content -->
    </el-card>
    <!-- card -->
  </div>
</template>

<script>
import { addUnit } from '@/api/unitmanage'

export default {
  data() {
    return {
      // 表单配置
      formConfig: [
        {
          label: '单位名称',
          field: 'unitName',
          type: 'text',
          rules: [{ required: true, message: '单位名称必填', trigger: 'blur' }]
        },
        {
          label: '单位中文简写',
          field: 'shortCn',
          type: 'text',
          rules: [
            { required: true, message: '单位中文简写必填', trigger: 'blur' }
            // { min: 1, max: 5, message: '简写长度为1到5', trigger: 'blur' }
          ]
        },
        {
          label: '单位英文简写',
          field: 'shortEn',
          type: 'text',
          rules: [
            // { required: true, message: '单位中文简写必填', trigger: 'blur' },
            // { min: 1, max: 5, message: '简写长度为1到5', trigger: 'blur' }
          ]
        }
        // {
        //   label: '手机号',
        //   field: 'mobilePhone',
        //   type: 'text',
        //   rules: [
        //     { required: true, message: '手机号必填', trigger: 'blur' },
        //     {
        //       validator: this.checkMobilePhone,
        //       trigger: 'blur'
        //     }
        //   ]
        // },
        // {
        //   label: '角色',
        //   field: 'roleId',
        //   type: 'select',
        //   payload: [],
        //   rules: [{ required: true, message: '角色必填', trigger: 'blur' }]
        // },
        // {
        //   label: '密码',
        //   field: 'password',
        //   type: 'password',
        //   showPwd: true,
        //   rules: [{ required: true, message: '密码必填', trigger: 'blur' }]
        // }
      ],
      formBtns: [
        {
          content: '创建',
          width: '185px',
          type: 'primary'
        },
        {
          content: '重置',
          width: '185px',
          type: ''
        }
      ]
    }
  },
  methods: {
    submitFn(formData) {
      console.log(formData, '单位创建')
      if (this.submited) return
      this.$loading({
        text: '单位信息添加中'
      })
      let unitinfo = JSON.parse(JSON.stringify(formData))
      let addparams = {
        name: unitinfo.unitName,
        chineseAbbreviations: unitinfo.shortCn,
        englishAbbreviation: unitinfo.shortEn
      }
      addUnit(addparams).then((res) => {
        console.log(res.data, '创建')
        if (res.code == 200) {
          this.submited = true
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.$router.go(-1)
        }
      })
      this.$loading().close()
    }
  }
}
</script>

<style></style>
