<template>
  <div style="width: 100%; height: 100%" ref="mychart"></div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: 'ChartsBar',
  props: {
    title: {
      type: String,
      required: true
    },
    topText: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  watch: {
    data() {
      this.initMyChart() // 重置图表
    }
  },
  mounted() {
    // this.initMyChart()
    setTimeout(() => {
      this.initMyChart()
      const resizeOb = new ResizeObserver((entries) => {
        for (const entry of entries) {
          echarts.getInstanceByDom(entry.target).resize()
        }
      })
      resizeOb.observe(this.$refs.mychart)
    })
  },
  // beforeDestroy() {
  //   window.removeEventListener('resize', this.resize)
  // },
  methods: {
    // resize() {
    //   this.mychart.resize()
    // },
    initMyChart() {
      // 基于准备好的dom，初始化echarts实例
      let mychart = echarts.getInstanceByDom(this.$refs.mychart)
      if (mychart == null) mychart = echarts.init(this.$refs.mychart)
      // const xAxisData = this.data.map((item) => item.name)
      // const seriesData = this.data.map((item) => item.score)
      let dataArr = []
      // 滑动
      let showEchart = false
      let namenum = 0
      if (this.data) {
        dataArr = this.data.map((el) => {
          return {
            品牌得分: el.aveScore,
            Title: el.unitProduction
          }
        })
      }

      // let dataArr = [
      //   {
      //     品牌得分: 24,
      //     // 测试2: 12,
      //     // 测试3: 67,
      //     // 测试4: 54,
      //     // 测试5: 23,
      //     // 测试6: 56,
      //     Title: '表头1'
      //   },
      //   {
      //     品牌得分: 24,
      //     // 测试2: 78,
      //     // 测试3: 24,
      //     // 测试4: 22,
      //     // 测试5: 24,
      //     // 测试6: 56,
      //     Title: '表头2'
      //   },
      //   {
      //     品牌得分: 24,
      //     // 测试2: 12,
      //     // 测试3: 78,
      //     // 测试4: 66,
      //     // 测试5: 87,
      //     // 测试6: 56,
      //     Title: '表头3'
      //   },
      //   {
      //     品牌得分: 24,
      //     // 测试2: 54,
      //     // 测试3: 35,
      //     // 测试4: 75,
      //     // 测试5: 54,
      //     // 测试6: 56,
      //     Title: '表头4'
      //   },
      //   {
      //     品牌得分: 24,
      //     // 测试2: 87,
      //     // 测试3: 25,
      //     // 测试4: 57,
      //     // 测试5: 24,
      //     // 测试6: 56,
      //     Title: '表头5'
      //   },
      //   {
      //     品牌得分: 24,
      //     // 测试2: 75,
      //     // 测试3: 24,
      //     // 测试4: 35,
      //     // 测试5: 24,
      //     // 测试6: 56,
      //     Title: '表头6'
      //   },
      //   {
      //     品牌得分: 24,
      //     // 测试2: 77,
      //     // 测试3: 57,
      //     // 测试4: 85,
      //     // 测试5: 24,
      //     // 测试6: 56,
      //     Title: '表头7'
      //   },
      //   {
      //     品牌得分: 24,
      //     // 测试2: 7,
      //     // 测试3: 75,
      //     // 测试4: 12,
      //     // 测试5: 24,
      //     // 测试6: 56,
      //     Title: '表头8'
      //   }
      // ]
      // 取表头
      let tableHead = []
      for (var e = 0; e < dataArr.length; e++) {
        tableHead.push(dataArr[e].Title)
      }
      if (tableHead.length > 0) {
        namenum = Math.floor(100 / (tableHead.length / 10))
        if (tableHead.length > 10) {
          showEchart = true
        } else {
          showEchart = false
        }
      }
      let totalArr = []
      // 左侧标题
      var letftTitle = ['品牌得分']
      function SeriesData() {
        let arr = []
        for (var i = 0; i < letftTitle.length; i++) {
          let arr2 = []
          for (var e = 0; e < tableHead.length; e++) {
            let obj = dataArr.find((x) => x.Title == tableHead[e])
            if (Object.keys(obj).find((x) => x == letftTitle[i])) {
              let a = obj[Object.keys(obj).find((x) => x == letftTitle[i])]
              arr2.push(a)
            }
          }
          // 将数据汇总
          totalArr.push(arr2)
          let obj = {
            name: letftTitle[i],
            type: 'bar',
            label: {
              show: true,
              position: 'top'
            },
            yAxisIndex: 0,
            data: arr2
          }
          arr.push(obj)
        }
        return arr
      }
      function fucIcon() {
        let obj = {}
        for (var i = 0; i < letftTitle.length; i++) {
          obj[i] = { fontSize: 20, color: color[i] }
        }
        return obj
      }
      // 左侧标题显示
      function ForName(NameArr) {
        let TitleName = ''
        for (var i = 0; i < NameArr.length; i++) {
          TitleName += '{' + i + '|●}' + NameArr[i] + '\n'
        }
        return TitleName
      }
      // 绘制表格线
      function getTableLine(num) {
        var list = []
        var bottom = letftTitle.length * 30 + 65 // 可根据表格行数高度动态调整
        var height = 30 // 表格线行高
        // 循环绘制表格
        for (var i = 0; i < num; i++) {
          list.push({
            type: 'line',
            bottom: bottom - i * height,
            right: 80,
            style: {
              fill: '#333'
            },
            // 线条
            shape: {
              x1: 0,
              y1: 0,
              x2: 2000,
              y2: 0
            }
          })
        }
        return list
      }
      // 绘制表格线行数
      var lineList = getTableLine(letftTitle.length + 1)
      // 绘制表格数据
      function DrawTable(value) {
        let str = ''
        str += '{table|' + value + '}\n{table|'
        for (let i = 0; i < totalArr.length; i++) {
          str += totalArr[i][i] + '}\n{table|'
        }
        str += '}'
        return str
      }
      let color = [
        '#5470c6',
        '#fac858',
        '#73c0de',
        '#ee6666',
        '#3ba272',
        '#fc8452',
        '#9a60b4',
        '#ea7ccc'
      ]
      let option = {
        // 颜色配置
        color: color,
        legend: {
          // orient: 'horizontal',
          // top: 10,
          x: '90%',
          top: '10%',
          textStyle: {
            color: '#90979c'
          },
          data: letftTitle
        },
        // 左侧标题配置
        title: [
          {
            // text: ForName(letftTitle),
            text: this.title,
            left: 'center',
            top: 15,
            textStyle: {
              fontSize: 16,
              fontWeight: 'normal'
            }
            // bottom: 30 - letftTitle.length,
            // left: 0,
            // textStyle: {
            //   lineHeight: 30,
            //   fontSize: 13,
            //   fontWeight: 'normal',
            //   formatter: function (value) {
            //     return '{table|' + value + '}'
            //   },
            //   rich: fucIcon()
            // }
          },
          {
            text: ForName(letftTitle),
            // text: this.title,
            // left: 'center',
            // top: 15,
            // textStyle: {
            //   fontSize: 16,
            //   fontWeight: 'normal'
            // }
            bottom: 30 - letftTitle.length,
            left: 0,
            textStyle: {
              lineHeight: 30,
              fontSize: 13,
              fontWeight: 'normal',
              formatter: function (value) {
                return '{table|' + value + '}'
              },
              rich: fucIcon()
            }
          }
        ],
        // 表格边距配置
        grid: {
          bottom: letftTitle.length * 30 + 100, // 可根据表格行数动态调整高度
          // left: 100,
          // right: 80,
          left: '10%',
          right: '16%',
          top: 90
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {}
          }
        },
        // dataZoom: {
        //   show: true,
        //   realtime: true,
        //   start: 0,
        //   end: 50
        // },
        // 左右滑动
        dataZoom: [
          {
            type: 'slider',
            realtime: true,
            start: 0,
            end: namenum, // 数据窗口范围的结束百分比。范围是：0 ~ 100。
            height: 5, //组件高度
            left: 5, //左边的距离
            right: 5, //右边的距离
            bottom: 10, //下边的距离
            show: showEchart, // 是否展示
            fillerColor: 'rgba(17, 100, 210, 0.42)', // 滚动条颜色
            borderColor: 'rgba(17, 100, 210, 0.12)',
            handleSize: 0, //两边手柄尺寸
            showDetail: false, //拖拽时是否展示滚动条两侧的文字
            zoomLock: true, //是否只平移不缩放
            moveOnMouseMove: false, //鼠标移动能触发数据窗口平移
            //zoomOnMouseWheel: false, //鼠标移动能触发数据窗口缩放

            //下面是自己发现的一个问题，当点击滚动条横向拖拽拉长滚动条时，会出现文字重叠，导致效果很不好，以此用下面四个属性进行设置，当拖拽时，始终保持显示六个柱状图，可结合自己情况进行设置。添加这个属性前后的对比见**图二**
            startValue: 0, // 从头开始。
            endValue: 6, // 最多六个
            minValueSpan: 6, // 放大到最少几个
            maxValueSpan: 6 //  缩小到最多几个
          },
          {
            type: 'inside', // 支持内部鼠标滚动平移
            start: 0,
            end: namenum,
            zoomOnMouseWheel: false, // 关闭滚轮缩放
            moveOnMouseWheel: true, // 开启滚轮平移
            moveOnMouseMove: true // 鼠标移动能触发数据窗口平移
          }
        ],
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: tableHead,
            axisTick: {
              length: letftTitle.length * 30 + 35
            },
            axisLabel: {
              interval: 0,
              // rotate: 10
              formatter: function (params) {
                var newParamsName = ''
                var paramsNameNumber = params.length
                var provideNumber = 5 //一行显示几个字
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber)
                if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ''
                    var start = p * provideNumber
                    var end = start + provideNumber
                    if (p == rowNumber - 1) {
                      tempStr = params.substring(start, paramsNameNumber)
                    } else {
                      tempStr = params.substring(start, end) + '\n'
                    }
                    newParamsName += tempStr
                  }
                } else {
                  newParamsName = params
                }
                return DrawTable(params), newParamsName
              },
              rich: {
                table: {
                  height: 30, //表格内数据行高
                  lineHeight: 30,
                  align: 'center',
                  verticalAlign: 'bottom'
                }
              }
            }
            // axisLabel: {
            //   formatter: function (value) {
            //     console.log(value, '----------------999')
            //     // var indexNum = 0
            //     // for (var i = 0; i < tableHead.length; i++) {
            //     //   if (value == tableHead[i]) {
            //     //     indexNum = i
            //     //   }
            //     // }
            //     return DrawTable(value)
            //   },
            //   rich: {
            //     table: {
            //       height: 30, //表格内数据行高
            //       lineHeight: 30,
            //       align: 'center',
            //       verticalAlign: 'bottom'
            //     }
            //   }
            // }
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            minInterval: 1,
            splitLine: {
              show: false
            },
            // min: function (v) {
            //   return Math.max(v.min - 10, 0)
            // }
            min: (value) => {
              return value.min - 1
            },
            max: (value) => {
              // return Math.ceil(value.max / 100) * 100 + 300
              return value.max
            }
          }
        ],
        series: SeriesData(),
        graphic: lineList
      }

      mychart.setOption(option)
      // window.addEventListener('resize', this.resize)
    }
  }
}
</script>
