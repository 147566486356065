<template>
  <div class="create">
    <!-- card -->
    <el-card class="box-card">
      <!-- card header -->
      <div slot="header" class="clearfix">
        <span>角色权限创建</span>
        <el-button
          class="tz-r tz-create-btn"
          type="primary"
          round
          @click="jump('/roleRights')"
          >返回</el-button
        >
      </div>
      <!-- card header -->

      <!-- card content -->
      <tz-form
        width="110px"
        :formConfig="formConfig"
        :formBtns="formBtns"
        @submit="submitFn"
      ></tz-form>
      <!-- card content -->
    </el-card>
    <!-- card -->
  </div>
</template>

<script>
// import { DASHBOARD_MODULE } from '@/utils/params'
import { brequest } from '@/utils/brequest'
export default {
  data() {
    return {
      // 表单配置
      formConfig: [
        {
          label: '角色名称',
          field: 'roleName',
          type: 'text',
          rules: [{ required: true, message: '角色名称必填', trigger: 'blur' }]
        },
        // {
        //   label: '接口权限',
        //   field: 'accessResource',
        //   type: 'text',
        //   rules: [{ required: true, message: '接口权限必填', trigger: 'blur' }]
        // },
        {
          label: '菜单权限',
          field: 'menus',
          type: 'select',
          multiple: true,
          payload: [],
          rules: [{ required: true, message: '菜单必选', trigger: 'blur' }]
        }
        // {
        //   label: '仪表盘',
        //   field: 'dashboard',
        //   type: 'select',
        //   multiple: true,
        //   payload: DASHBOARD_MODULE,
        //   rules: [{ required: true, message: '仪表盘必选', trigger: 'blur' }]
        // }
      ],
      formBtns: [
        {
          content: '创建',
          width: '185px',
          type: 'primary'
        },
        {
          content: '重置',
          width: '185px',
          type: ''
        }
      ]
    }
  },
  created() {
    this.queryAllMenus()
  },
  methods: {
    // 查看菜单权限
    async queryAllMenus() {
      let ret = await brequest({
        url: '/user/queryAllMenus'
      })
      this.formConfig.find((res) => res.field == 'menus').payload = ret
        .filter((rec) => rec.parentId > 0)
        .map((rec) => {
          return {
            value: rec.id,
            label: rec.title
          }
        })
      console.log(this.formConfig)
    },
    async submitFn(role) {
      console.log(role, '信息创建')
      // if (this.submited) return
      this.$loading({
        text: '信息添加中'
      })
      // role.menu = role.menus.join()
      // role.dashboardModule = role.dashboard.join()
      // console.log(role)
      let ret = await brequest({
        url: '/user/saveRoleInfo',
        // data: role
        data: {
          roleName: role.roleName,
          accessResource: role.accessResource,
          menu: role.menus,
          dashboardModule: role.dashboard
        }
      })

      if (ret) {
        this.$message({
          message: '角色权限创建成功',
          type: 'success'
        })
      }
      this.$loading().close()
      this.$router.go(-1)
    }
  }
}
</script>

<style></style>
