<template>
  <div class="create">
    <!-- card -->
    <el-card class="box-card">
      <!-- card header -->
      <div slot="header" class="clearfix">
        <span>单位创建</span>
        <el-button
          class="tz-r tz-create-btn"
          type="primary"
          round
          @click="jump('/unitrental')"
          >返回</el-button
        >
      </div>
      <!-- card header -->

      <!-- card content -->
      <tz-form
        width="110px"
        :formConfig="formConfig"
        :formBtns="formBtns"
        @submit="submitFn"
      ></tz-form>
      <!-- card content -->
    </el-card>
    <!-- card -->
  </div>
</template>

<script>
import { addUnit, queryUnit } from '@/api/unitrental'
export default {
  data() {
    return {
      // 表单配置
      formConfig: [
        {
          label: '租赁单位',
          field: 'unitName',
          type: 'text',
          width: '180px',
          rules: [{ required: true, message: '单位名称必填', trigger: 'blur' }]
        },
        {
          label: '租赁单位关联单位',
          field: 'inspectUnitDevices',
          type: 'select',
          width: '180px',
          multiple: true,
          filterable: true,
          payload: [],
          rules: [{ required: true, message: '关联单位必选', trigger: 'blur' }]
        }
      ],
      formBtns: [
        {
          content: '创建',
          width: '185px',
          type: 'primary'
        },
        {
          content: '重置',
          width: '185px',
          type: ''
        }
      ]
    }
  },
  created() {
    this.queryUnitFn()
  },
  methods: {
    //租赁单位字典
    queryUnitFn() {
      var data = {}
      queryUnit(data).then((res) => {
        console.log(res.data, '租赁单位')
        this.formConfig[1].payload = res.data
      })
    },
    submitFn(formData) {
      console.log(formData, '单位创建')
      if (this.submited) return
      this.$loading({
        text: '单位信息添加中'
      })
      let unitinfo = JSON.parse(JSON.stringify(formData))
      let inspectUnitDevices = []
      if (unitinfo.inspectUnitDevices) {
        inspectUnitDevices = unitinfo.inspectUnitDevices.map((el) => {
          return { unitNameDevice: el }
        })
      }
      let addparams = {
        unitName: unitinfo.unitName,
        inspectUnitDevices: inspectUnitDevices
      }
      console.log(addparams)
      addUnit(addparams).then((res) => {
        console.log(res.data, '创建')
        if (res.code == 200) {
          this.submited = true
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.$router.go(-1)
        }
      })
      this.$loading().close()
    }
  }
}
</script>

<style></style>
