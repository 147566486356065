<template>
  <div class="info">
    <el-form label-width="80px" class="demo-ruleForm">
      <el-form-item label="登录名">
        <el-input v-model="userInfo.loginName" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="用户名">
        <el-input v-model="userInfo.userName" :disabled="true"></el-input>
      </el-form-item>

      <el-form-item label="电话">
        <el-input v-model="userInfo.mobilePhone" :disabled="true"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  inject: ['reload'],
  data() {
    return {
      userInfo: {
        userName: '',
        mobilePhone: '',
        loginName: ''
      }
    }
  },
  methods: {},
  beforeCreate() {},
  created() {
    //获取跳转页面的传参
    // this.userInfo = this.$route.params

    this.userInfo.userName = window.localStorage.getItem('userName')
    this.userInfo.loginName = window.localStorage.getItem('loginName')
    this.userInfo.mobilePhone = window.localStorage.getItem('mobilePhone')

    console.log(this.userInfo)
  },
  mounted() {
    //刷新
    // console.log(this.$route.meta.reload.indexOf('one'))
    if (this.$route.meta.reload.indexOf('one') == -1) {
      console.log('刷新')
      this.$route.meta.reload = 'one'
      this.reload()
      // this.$route.meta.reload = ''
    }
  },
  beforeDestroy() {}
}
</script>
<style lang="scss" scoped>
.info {
  text-align: center;
  display: flex;
  justify-content: center;
}
</style>
