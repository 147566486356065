<template>
  <div style="width: 100%; height: 100%" ref="mychart"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'ChartsBar',
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    data() {
      this.initMyChart() // 重置图表
    }
  },
  mounted() {
    // this.initMyChart()
    setTimeout(() => {
      this.initMyChart()
      const resizeOb = new ResizeObserver((entries) => {
        for (const entry of entries) {
          echarts.getInstanceByDom(entry.target).resize()
        }
      })
      resizeOb.observe(this.$refs.mychart)
    })
  },
  methods: {
    initMyChart() {
      let mychart = echarts.getInstanceByDom(this.$refs.mychart)
      if (mychart == null) mychart = echarts.init(this.$refs.mychart)
      let seriesData = []
      let xData = []
      let yData = {}
      let legendData = []
      let showEchart = false
      let namenum = 0
      if (this.data) {
        seriesData = this.data.deviceData
        console.log(seriesData)
        // seriesData.push({
        //   itemStyle: {
        //     normal: {
        //       color: '#4ad2ff'
        //     }
        //   }
        // })
        xData = this.data.area
        if (xData) {
          if (xData.length > 0) {
            namenum = Math.floor(100 / (xData.length / 5))
            if (xData.length > 5) {
              showEchart = true
            } else {
              showEchart = false
            }
          }
        }
        yData = {
          type: 'bar',
          barMaxWidth: '40%',
          label: {
            //图形上的文本标签
            show: true,
            position: 'inside',
            textStyle: {
              // color: '#a8aab0',
              fontStyle: 'normal',
              fontFamily: '微软雅黑',
              fontSize: 10
            }
          },
          emphasis: {
            focus: 'series'
          }
        }
        // seriesData.push(...yData)

        for (let tempi in seriesData) {
          seriesData[tempi] = Object.assign(seriesData[tempi], yData)
          legendData.push(seriesData[tempi].name)
        }
      }

      const option = {
        // 标题
        title: {
          text: this.title,
          left: 'center',
          top: 15,
          textStyle: {
            fontSize: 16,
            fontWeight: 'normal'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid: {
          borderWidth: 0,
          top: 90,
          left: '4%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
          textStyle: {
            color: '#000'
          }
        },
        legend: {
          x: '4%',
          top: '10%',
          // top: 'bottom',
          data: legendData
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            axisPointer: {
              type: 'shadow'
            },
            // axisLabel: {
            //   interval: 0,
            //   rotate: 20
            // }
            axisLabel: {
              //x轴文字的配置
              show: true,
              interval: 0, //使x轴文字显示全
              textStyle: {
                color: '#90979c'
              },
              formatter: function (params) {
                var newParamsName = ''
                var paramsNameNumber = params.length
                var provideNumber = 5 //一行显示几个字
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber)
                if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ''
                    var start = p * provideNumber
                    var end = start + provideNumber
                    if (p == rowNumber - 1) {
                      tempStr = params.substring(start, paramsNameNumber)
                    } else {
                      tempStr = params.substring(start, end) + '\n'
                    }
                    newParamsName += tempStr
                  }
                } else {
                  newParamsName = params
                }
                return newParamsName
              }
            }
          }
        ],
        yAxis: [
          {
            minInterval: 1,
            type: 'value',
            min: (value) => {
              // return value.min
              return Math.floor(value.min / 10) * 10
            },
            max: (value) => {
              // return Math.ceil(value.max / 100) * 100 + 300
              return Math.ceil(value.max)
            }
          }
        ],
        dataZoom: [
          {
            type: 'slider',
            realtime: true,
            start: 0,
            end: namenum, // 数据窗口范围的结束百分比。范围是：0 ~ 100。
            height: 5, //组件高度
            left: 5, //左边的距离
            right: 5, //右边的距离
            bottom: 10, //下边的距离
            show: showEchart, // 是否展示
            fillerColor: 'rgba(17, 100, 210, 0.42)', // 滚动条颜色
            borderColor: 'rgba(17, 100, 210, 0.12)',
            handleSize: 0, //两边手柄尺寸
            showDetail: false, //拖拽时是否展示滚动条两侧的文字
            zoomLock: true, //是否只平移不缩放
            moveOnMouseMove: false, //鼠标移动能触发数据窗口平移
            //zoomOnMouseWheel: false, //鼠标移动能触发数据窗口缩放

            //下面是自己发现的一个问题，当点击滚动条横向拖拽拉长滚动条时，会出现文字重叠，导致效果很不好，以此用下面四个属性进行设置，当拖拽时，始终保持显示六个柱状图，可结合自己情况进行设置。添加这个属性前后的对比见**图二**
            startValue: 0, // 从头开始。
            endValue: 6, // 最多六个
            minValueSpan: 6, // 放大到最少几个
            maxValueSpan: 6 //  缩小到最多几个
          },
          {
            type: 'inside', // 支持内部鼠标滚动平移
            start: 0,
            end: namenum,
            zoomOnMouseWheel: false, // 关闭滚轮缩放
            moveOnMouseWheel: true, // 开启滚轮平移
            moveOnMouseMove: true // 鼠标移动能触发数据窗口平移
          }
        ],
        series: seriesData
      }
      mychart.setOption(option, true)
    }
  }
}
</script>
